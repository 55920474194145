import React, { useEffect } from "react"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import BannerModuleWrapper from "../components/BannerModule/BannerModuleWrapper"
import { graphql } from 'gatsby'
import PerksModuleAccoglienza from "../components/PerksModule/PerksModuleAccoglienza"
import Perk from "../components/PerksModule/Perk"

export default function FormSent({ data }) {

  const titles = ["Tutto a posto, messaggio inviato", "Accoglienza"]
  const subtitles = ["Ti rispondermo il prima possibile, grazie", "Adatto a famiglie e piccoli gruppi"]
  const linkTexts = ["torna alla pagina iniziale", "contattaci"]
  const linkUrls =["/","#","#","#"]


  return (
    <>
      <Seo title="Messaggio inviato" />
      <Layout>
        {<BannerModuleWrapper
          max={0}
          titles={titles}
          subtitles={subtitles}
          linkTexts={linkTexts}
          images={data.allWp.edges[0].node.accoglienzaSettings.backgrounds_accoglienza.bgsAccoglienza}
          linkUrls={linkUrls}
        />}



        <PerksModuleAccoglienza >
          <Perk title="The Title" content="The content"  />
        </PerksModuleAccoglienza>

     

       
      </Layout>
    </>
  )
}



export const imagesWp = graphql`
{
  allWp {
    edges {
      node {
        accoglienzaSettings {
                accoglienza_contenuti {
                    testiAccoglienza {
              paragrafo
              titolo
              galleria {
                localFile {
                  children {
                    ... on ImageSharp {
                      id
                      resize(height: 168, width: 300, cropFocus: CENTER) {
                        src
                      }
                    }
                  }
                  childrenImageSharp {
                    original {
                      src
                      width
                      height
                    }
                  }
                }
              }
            }
            
          }
          backgrounds_accoglienza  {
            bgsAccoglienza {
              localFile {
                childrenImageSharp {
                  gatsbyImageData(
                    aspectRatio: 1.7
                    placeholder: BLURRED
                    formats: AUTO
                    layout: FULL_WIDTH
                  )
                }
              }
            }
          }
        }
      }
    }
  }
}
`