import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { PerksModuleStyles } from "./PerksModuleStyles"
import { MdOutlineClose as Cross } from "react-icons/md"
import { MdWest, MdEast } from "react-icons/md"
import { CgDanger } from "react-icons/cg"
import { MdOutlineReportProblem } from "react-icons/md"
import {GrStatusGood}from "react-icons/gr"
import {AiTwotoneCheckCircle}from "react-icons/ai"
import { GrSchedule } from "react-icons/gr"
import { GiMountaintop } from "react-icons/gi"
import {RiOpenArmFill} from "react-icons/ri"
import Perk from "./Perk"
import ListItem from "./ListItem"



const PerksModuleAccoglienza = () => {
    return (
        <PerksModuleStyles id="perksIndex" className="section section__padding">
          {/* <h2>Element is inside the viewport: {inView.toString()}</h2> */}
          {/* <StaticImage
            className="perks__image--bg"
            src="../../../static/foglie_bg.jpg"
            alt="Foglie"
            layout="constrained"
            transformOptions={{duotone: { highlight: "#ffc400", shadow: "#ffc400", opacity: 100 } }}
            placeholder="tracedSVG"
          /> */}
          {/* <div className="perks__image--overlay"></div> */}
          <div className="container container__tight">
            <Perk
              title="È raggiungibile solo a piedi con minimo 2 ore di cammino"
              content="scopri come"
              link="/dove"
            >
              {/* <StaticImage
                src="../../../static/hike.png"
                alt="Perk Image"
                layout="fullWidth"
                placeholder="tracedSVG"
              /> */}
              <div className="iconBig">
                <GiMountaintop />
              </div>
            </Perk>
            
              {/* <Cross /> */}
             {/*  <div className="icon-top"><MdNorth /></div>
              <div className="text">Il rifugio Cognolo</div>
              <div className="icons"><MdWest /><MdEast /></div>
              <div className="icon-bottom"><MdSouth /></div> */}
               <Perk
              title="È aperto ogni terzo week-end del mese"
              content="Contattaci per la conferma"
              link="/contatti"
            >
              <div className="iconBig">
                <RiOpenArmFill />
              </div>
            </Perk>
            
            <Perk
              title="È aperto tutto l'anno solo su prenotazione"
              content="prenota ora"
              link="/contatt"
            >
              {/* <StaticImage
                src="../../../static/book.png"
                alt="Perk Image"
                layout="fullWidth"
                placeholder="tracedSVG"
              /> */}
              <div className="iconBig">
                <GrSchedule />
              </div>
            </Perk>
          </div>
        </PerksModuleStyles>
      )
}

export default PerksModuleAccoglienza
